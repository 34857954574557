import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { NotFoundPage } from './NotFoundPage';
import { PageRoutes } from './PageRoutes';
import { RegisterPage } from './pages/register-page/RegisterPage';

export const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path={'/'}
            element={<RegisterPage />}
          />
          <Route
            path='*'
            element={<NotFoundPage />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
