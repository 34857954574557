import { useState } from 'react';
import { validEmailRegex } from '../../shared/regex';
import './RegisterPage.scss';


export const RegisterPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [productCode, setProductCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [licenseCode, setLicenseCode] = useState<string | undefined>();

  const registerNewUser = async (): Promise<string | undefined> => {
    const formData = new URLSearchParams();
    formData.append('email', email);
    formData.append('product_code', productCode);
    const options =
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*"
      },
      body: formData.toString(),
      json: true,
    }
    const response = await fetch(
      'https://registration.ewds.tech/api/user/index.php',
      options,
    );

    return await response.text();
  }

  const onSubmit = async () => {
    if (!validEmailRegex.test(email)) {
      alert('Please enter valid email address.');
      return;
    }
    if (productCode === '') {
      alert('Please enter product code before submitting.');
      return;
    }
    setIsLoading(true);
    registerNewUser()
      .then((response) => {
        setLicenseCode(response);
      })
      .catch(error => alert(error))
      .finally(() => setIsLoading(false));
  }

  return (
    <div
      className='register-page'
    >
      <h1>EWDS Registration Page</h1>
      <a href='https://drive.google.com/drive/folders/1ROf5q8rog60Eym_GeBxCv2-RUQA_WmVY?usp=sharing' target={'_blank'} rel="noreferrer">
        <h3>Download update software terbaru</h3>
      </a>
      <p>PERHATIAN!</p>
      <p>Registrasi HANYA dapat dilakukan 1x saja untuk 1 software EWDS Tools.</p>
      <p>Silakan masukan alamat email dan kode produk anda dengan BENAR.</p>
      <div className='register-form'>
        <div className='form-items'>
          <label
            htmlFor='email'
          >
            Email
          </label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            disabled={isLoading}
          />
        </div>
        <div className='form-items'>
          <label
            htmlFor='product-code'
          >
            Kode Produk
          </label>
          <textarea
            name="product-code"
            value={productCode}
            onChange={event => setProductCode(event.target.value)}
            disabled={isLoading}
          />
        </div>
        <div className='form-items align-center'>
          <button
            onClick={onSubmit}
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
        {
          !isLoading && licenseCode &&
          <div className='form-items'>
            <div>
              <label
                htmlFor='product-code'
              >
                Product Key
              </label>
              <button
                className='button-small'
                onClick={() => {
                  navigator.clipboard.writeText(licenseCode);
                  alert('Product key berhasil di salin.');
                }}
              >
                Copy
              </button>
            </div>
            <textarea
              className='license-code'
              value={licenseCode}
              disabled
            />
          </div>
        }
      </div>
      <p>
        Product key Anda akan ditampilkan "1x SAJA" setelah registrasi berhasil.
      </p>
      <p>
        Segera salin product key setelah registrasi berhasil. Setelah halaman ini di refresh, product key tidak akan ditampilkan kembali.
      </p>
    </div>
  )
}